let gsap = null;
let ScrollTrigger = null;
let lenis = null;
let loading = true;

export const setGSAP = (gsapInstance) => {
  gsap = gsapInstance;
};

export const setScrollTrigger = (ScrollTriggerInstance) => {
  ScrollTrigger = ScrollTriggerInstance;
};

export const setLenis = (lenisInstance) => {
  lenis = lenisInstance;
};

export const setLoading = (loadingInstance) => {
  loading = loadingInstance;
};

export const getGSAP = () => gsap;
export const getScrollTrigger = () => ScrollTrigger;
export const getLenis = () => lenis;
export const isLoading = () => loading;
