<script setup></script>

<template>
  <footer class="z-[1] bottom-0 p:pb-[60px] tm:pb-[150px]">
    <ul
      class="flex justify-center items-center p:divide-x p:text-[12px] tm:text-[32px] tm:flex-col"
    >
      <li class="p:px-[20px]">
        <a href="tel:0226982288" target="_blank">客服電話：02-2698-2288</a>
      </li>
      <li class="p:px-[20px]">
        <p>廣德昌台灣有限公司（正官庄大中華區總代理）</p>
      </li>
      <li class="p:px-[20px]">
        <p>Kwong Tack Cheong Taiwan Ltd.(Greater China-Sole Agent)</p>
      </li>
    </ul>
  </footer>
</template>
<style lang="postcss" scoped></style>
