<script setup>
const props = defineProps({
  images: {
    type: [String, Object],
    required: true,
    validator: (value) => {
      if (typeof value === 'string') return true;
      if (typeof value === 'object') {
        return ['pc', 'm'].some((size) => size in value);
      }
      return false;
    },
  },
  alt: {
    type: String,
    default: '',
  },
  className: {
    type: String,
    default: 'w-full',
  },
});

const imageSources = ref([]);

const breakpoints = {
  m: 975,
  pc: 1440,
};

const imageFiles = {
  ...import.meta.glob('@img/**/*.png', { eager: true }),
  ...import.meta.glob('@img/**/*.jpg', { eager: true }),
  ...import.meta.glob('@img/**/*.jpeg', { eager: true }),
  ...import.meta.glob('@img/**/*.svg', { eager: true }),
  ...import.meta.glob('@img/**/*.webp', { eager: true }),
};

onMounted(() => {
  if (typeof props.images === 'string') {
    const imgKey = Object.keys(imageFiles).find((path) => {
      const fileName = path.split('/').pop(); // 获取路径中的文件名部分
      return fileName === props.images;
    });
    if (imgKey) {
      imageSources.value.push({
        src: imageFiles[imgKey].default,
        type: getImageType(props.images),
      });
    }
  } else {
    // If images is an object, create sources for each size
    Object.entries(breakpoints).forEach(([size, width]) => {
      if (props.images[size]) {
        const imgKey = Object.keys(imageFiles).find((path) => {
          const fileName = path.split('/').pop(); // 获取路径中的文件名部分
          return fileName === props.images[size];
        });
        if (imgKey) {
          imageSources.value.push({
            src: imageFiles[imgKey].default,
            media: `(max-width: ${width}px)`,
            type: getImageType(props.images[size]),
          });
        }
      }
    });

    // Add default image if provided
    if (props.images.default) {
      const defaultImgKey = Object.keys(imageFiles).find((path) =>
        path.includes(props.images.default)
      );
      if (defaultImgKey) {
        imageSources.value.push({
          src: imageFiles[defaultImgKey].default,
          type: getImageType(props.images.default),
        });
      }
    }
  }
});

function getImageType(src) {
  const extension = src.split('.').pop().toLowerCase();
  switch (extension) {
    case 'jpg':
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'webp':
      return 'image/webp';
    case 'svg':
      return 'image/svg+xml';
    default:
      return 'image/png'; // Default to JPEG if unknown
  }
}
</script>

<template>
  <picture class="block">
    <source
      v-for="source in imageSources"
      :key="source.src"
      :srcset="source.src"
      :media="source.media"
      :type="source.type"
    />
    <img
      :src="imageSources[imageSources.length - 1]?.src"
      :alt="props.alt"
      :class="props.className"
    />
  </picture>
</template>
