<script setup>
import { getGSAP, getScrollTrigger, isLoading } from '@composable/useGsap';
import { computed } from 'vue';

const isLoad = computed(() => isLoading());
</script>

<template>
  <div id="app" class="!w-full !max-w-full relative">
    <Header v-if="!isLoad" :key="$route.name" />
    <RouterView v-if="!isLoad" />
    <Footer
      v-if="$route.name !== 'home'"
      class="w-full divide-[#000] text-[#000] p:pt-[50px] tm:pt-[200px] absolute"
      :class="$route.meta.footer"
    />
  </div>
</template>

<style scoped></style>
